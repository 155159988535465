<template>
  <BModal
    :id="idProp"
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    no-enforce-focus
    hide-footer
    header-bg-variant="light-info"
    :title="title"
    @show="showHandle"
  >
    <BAlert
      v-if="errorMessage"
      show
      variant="danger"
      class="px-2 py-1"
    >
      <div v-if="errorMessage === 'Permission denied'">
        <div>Camera không khả dụng!!!</div>
        <div>Vui lòng cấp quyền truy cập Camer của thiết bị. </div>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </BAlert>

    <qrcode-stream
      v-else
      :constraints="cameraConstraints"
      @decode="onDecode"
      @init="onInit"
      @error="onError"
    />
    <div
      v-if="result"
      class="my-1"
    >
      <div class="fw-600 text-info">
        Thông tin từ QR code:
      </div>
      <div>
        <h3>{{ result }}</h3>
      </div>

      <BButton
        rounded
        class="w-100 mt-1"
        variant="primary"
        @click="onApplyHandle"
      >
        Đồng ý
      </BButton>
    </div>

    <div
      v-else
      class="font-italic fw-600 d-flex-center"
    >
      Vui lòng đưa QR code trên CCCD vào camera.
    </div>

    <template #modal-backdrop>
      <div class="bg-dark w-100 h-100" />
    </template>
  </BModal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BAlert, BButton, BModal } from 'bootstrap-vue'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream, BModal, BAlert, BButton,
  },
  props: {
    idProp: { type: String, default: 'modal-qr-code-scan' },
    title: { type: String, default: 'QR Code Scan' },
  },
  setup(props, { emit, root }) {
    const result = ref(null)
    const errorMessage = ref()

    const cameraConstraints = ref({
      video: {
        width: { ideal: 1280 }, // Độ rộng video lý tưởng
        height: { ideal: 720 }, // Độ cao video lý tưởng
        facingMode: 'environment', // Sử dụng camera sau
        advanced: [{ focusMode: 'continuous' }],
      },
    })

    function onDecode(decodedString) {
      const splitResult = decodedString.split('|')
      result.value = splitResult[0] || splitResult[1]
    }

    function onInit(promise) {
      promise
        .then(() => {
          errorMessage.value = null
        })
        .catch(error => {
          console.error({ error })
          errorMessage.value = error.message
        })
    }

    function onApplyHandle() {
      emit('apply', result.value)
      root.$bvModal.hide(props.idProp)
    }

    function showHandle() {
      result.value = null
      errorMessage.value = null
    }

    function onError(error) {
      console.error('Lỗi khi sử dụng camera:', { error })
    }
    return {
      result,
      onDecode,
      onInit,
      errorMessage,
      onApplyHandle,
      showHandle,
      onError,
      cameraConstraints,
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-backdrop {
    opacity: 0.8;
  }
}
</style>
