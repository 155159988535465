var render = function () {
  var _vm$item, _vm$item2, _vm$item3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visibleForFloor(_vm.item) && _vm.visibleForRole(_vm.item) && _vm.visibleForRetail(_vm.item) && !(_vm.item.hideForProduction && _vm.isProduction || (_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.hideAll) && _vm.visibleByIsShowFunction ? _c('li', {
    staticClass: "nav-item has-sub",
    class: {
      'open': _vm.isOpen,
      'disabled': _vm.item.disabled || !_vm.canViewVerticalNavMenuGroup(_vm.item),
      'sidebar-group-active': _vm.isActive || _vm.isActiveBg
    }
  }, [_c('b-link', {
    staticClass: "d-flex align-items-center",
    class: _vm.item.route ? 'p-0' : '',
    on: {
      "click": function click() {
        return _vm.updateGroupOpen(!_vm.isOpen);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center rounded",
    class: {
      'hover-bg': _vm.item.route
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleClickName(_vm.item);
      }
    }
  }, [(_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.customIcon ? _c('IAmIcon', {
    staticClass: "mr-50",
    attrs: {
      "icon": (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.customIcon,
      "size": "18"
    }
  }) : _c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": _vm.item.icon || 'CircleIcon'
    }
  }), _c('div', {
    staticClass: "flex-1 d-flex-between gap-2"
  }, [!_vm.isVerticalMenuCollapsed || _vm.isMouseHovered || _vm.$store.state.verticalMenu.isShowText ? _c('span', {
    staticClass: "menu-title text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.item.title)) + " " + _vm._s(_vm.getRouteDescription(_vm.item)) + " ")]) : _vm._e(), (_vm.item.tag || _vm.item.hideForProduction) && (!_vm.isVerticalMenuCollapsed || _vm.isMouseHovered) ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": _vm.item.tagVariant || (_vm.item.hideForProduction ? 'light-danger' : '') || 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.tag || (_vm.item.hideForProduction ? 'DEV' : '')) + " ")]) : _vm._e()], 1)], 1)]), _c('b-collapse', {
    staticClass: "menu-content",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, _vm._l(_vm.item.children, function (child) {
    return _c(_vm.resolveNavItemComponent(child), {
      key: child.header || child.title,
      ref: "groupChild",
      refInFor: true,
      tag: "component",
      attrs: {
        "item": child
      }
    });
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }