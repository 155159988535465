var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": _vm.idProp,
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "no-enforce-focus": "",
      "hide-footer": "",
      "header-bg-variant": "light-info",
      "title": _vm.title
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('input', {
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onFileChange
    }
  }), _vm.image ? _c('div', [_c('div', {
    staticClass: "font-italic text-center"
  }, [_vm._v(" Vui lòng cắt ảnh vừa với QR code ")]), _c('vue-cropper', {
    ref: "cropperRef",
    staticStyle: {
      "width": "100%",
      "max-height": "400px"
    },
    attrs: {
      "src": _vm.image,
      "aspect-ratio": _vm.ASPECT_RATIO,
      "dragMode": "crop",
      "view-mode": 1,
      "auto-crop-area": 1
    }
  }), _c('BButton', {
    staticClass: "w-100 my-1",
    attrs: {
      "rounded": "",
      "variant": "warning"
    },
    on: {
      "click": _vm.getCroppedImage
    }
  }, [_vm._v(" Quét QR ")])], 1) : _vm._e(), _vm.result ? _c('div', [_c('div', {
    staticClass: "fw-600 text-info"
  }, [_vm._v(" Thông tin từ QR code: ")]), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.result))])]), _c('BButton', {
    staticClass: "w-100 mt-1",
    attrs: {
      "rounded": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.onApplyHandle
    }
  }, [_vm._v(" Đồng ý ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }