import csvtojson from 'csvtojson'
import ExcelJS from 'exceljs'
import isArray from 'lodash/isArray'

import api, { apiGeneral } from '@/api'

import { sizeFormatter } from '@core/utils/filter'

export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,
  }
}

export const uploadMultipleFile = (files, options = {}) => {
  const maxSize = options?.maxSize
  const uploadUser = options?.uploadUser

  const MAX_SIZE_UPLOAD = maxSize ? maxSize * 1024 * 1024 : process.env.VUE_APP_MAX_SIZE_FOR_UPLOAD_FILE
  const filesFromData = files.map(file => {
    if (file?.file && file?.url) return false
    const data = new FormData()
    data.append('file', file, file.name)

    if (file?.size > MAX_SIZE_UPLOAD) {
      throw new Error(`Hệ thống chỉ hỗ trợ upload File nhỏ hơn ${sizeFormatter(MAX_SIZE_UPLOAD)}`)
    }

    return {
      data,
      fileName: file.name,
    }
  })

  return Promise.all(filesFromData.filter(item => !!item).map(async item => {
    let res
    if (uploadUser) {
      res = await apiGeneral.uploadUserFile(item.data)
    } else {
      res = await apiGeneral.uploadImage(item.data)
    }

    return {
      url: res.url,
      file: item.fileName,
    }
  }))
}

export const useInputFileFormData = (inputEl, callback) => {
  async function inputFileFormData() {
    this.$root.$emit('bv::show::modal', 'modal-api-loading')
    const file = inputEl.value.files[0]
    const fileName = file?.name
    const MAX_SIZE_UPLOAD = 0.5 * 1024 * 1024 // 500kb
    if (file?.size > MAX_SIZE_UPLOAD) {
      this.$root.$emit('toastError', {
        title: 'File nhỏ hơn 500kB', // i18n key
      })
    }
    const data = new FormData()
    data.append('file', file, fileName)
    api.general
      .uploadImage(data)
      .then(res => {
        callback(res)
      })
      .catch(error => {
        console.error({ error })
      })
      .finally(() => {
        this.$root.$emit('bv::hide::modal', 'modal-api-loading')
      })
  }

  return {
    inputFileFormData,
  }
}

export const useInputImageFormData = (inputEl, callback) => {
  // If you like - you can move this section into separate file
  // REF: https://stackoverflow.com/a/60977049
  // ------- Move from here -------
  const dataURItoBlob = dataURI => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i)
    return new Blob([ia], { type: mime })
  }

  const resizeImage = ({ file, maxSize = 1000 }) => {
    const reader = new FileReader()
    const image = new Image()
    const canvas = document.createElement('canvas')

    const resize = () => {
      let { width, height } = image

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }

      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)

      const dataUrl = canvas.toDataURL('image/jpeg')

      return dataURItoBlob(dataUrl)
    }

    return new Promise((ok, no) => {
      if (!file?.type.match(/image.*/)) {
        no(new Error('Not an image'))
        return
      }

      reader.onload = readerEvent => {
        image.onload = () => ok(resize())
        image.src = readerEvent.target.result
      }

      reader.readAsDataURL(file)
    })
  }

  // export default resizeImage; // uncomment once moved to resizeImage.js
  // ------- till here - into ./src/plugins/image-resize.js -------

  async function inputImageFormData(maxSize = 0.5) {
    this.$root.$emit('bv::show::modal', 'modal-api-loading')
    let file = inputEl.value.files[0]

    const imageDimensions = await new Promise(resolve => {
      const image = new Image()
      const photoUrl = URL.createObjectURL(file)

      image.onload = () => {
        const dimensions = {
          height: image.height,
          width: image.width,
        }
        resolve(dimensions)
      }
      image.src = photoUrl
    })
    const { width } = imageDimensions
    const fileName = file.name
    const MAX_SIZE_UPLOAD = maxSize * 1024 * 1024 // 0.5=500kb, 1=1000kb, ...
    if (file?.size > MAX_SIZE_UPLOAD) {
      file = await resizeImage({
        file,
        maxSize: width ? width / (file?.size / MAX_SIZE_UPLOAD) : 1000, // FIXME
      })
    }
    const data = new FormData()
    data.append('file', file, fileName)
    api.general
      .uploadImage(data)
      .then(res => {
        callback(res)
      })
      .catch(error => {
        console.error({ error })
      })
      .finally(() => {
        this.$root.$emit('bv::hide::modal', 'modal-api-loading')
      })
  }

  return {
    inputImageFormData,
  }
}

export const useInputCsvToArray = (file, callback) => {
  // const inputCsvToArray = () => {
  const reader = new FileReader()

  if (file) {
    reader.readAsText(file)
  }

  reader.addEventListener(
    'load',
    () => {
      csvtojson({
        delimiter: [',', ';', '|', '-'],
      })
        .fromString(reader.result)
        .then(arrayList => {
          callback(arrayList)
        })
        .catch(error => {
          callback({ error })
        })
    },
    false,
  )

  // }
  // return {
  //   inputCsvToArray,
  // }
}

// TODO: DELETE original useInputExcelToArray after refactor all
export const useInputExcelToArray = file => new Promise((resolve, reject) => {
  const resultArray = []
  const wb = new ExcelJS.Workbook()
  const reader = new FileReader()
  let keyArray = []

  reader.readAsArrayBuffer(file)
  reader.onload = () => {
    const buffer = reader.result
    wb.xlsx
      .load(buffer)
      .then(workbook => {
        workbook.eachSheet(sheet => {
          sheet.eachRow((row, indexRow) => {
            if (indexRow === 1) {
              keyArray = [...row.values]
            } else {
              const obj = row.values.reduce((a, v, i) => {
                if (a[keyArray[i]]) {
                  throw Error(
                    `Wrong format: duplicate columns ${keyArray[i]}`,
                  )
                }
                // eslint-disable-next-line no-nested-ternary
                const value = typeof v === 'object'
                  ? v.text
                  : typeof v === 'string'
                    ? v.trim()
                    : v
                return { ...a, [keyArray[i]]: value }
              }, {})
              resultArray.push(obj)
            }
          })
        })
        resolve(resultArray)
      })
      .catch(error => {
        reject(error)
      })
  }
})

// NOTE: for @params colDefine type Object || Array
// use type Object when ONLY get data of SOME columns
// use type Array when get data of ALL columns
export const useExcelToArray = (file, colDefine) => new Promise((resolve, reject) => {
  const resultArray = []
  const wb = new ExcelJS.Workbook()
  const reader = new FileReader()
  const colDefineIsArr = isArray(colDefine) // true: Array || false: Object

  reader.readAsArrayBuffer(file)
  reader.onload = () => {
    const buffer = reader.result
    wb.xlsx
      .load(buffer)
      .then(workbook => {
        workbook.eachSheet(sheet => {
          sheet.eachRow((row, indexRow) => {
            if (indexRow === 1) return
            const arr = Array.from(Array(row.values.length))
              .map((item, index) => {
                if (index === 0) return
                // eslint-disable-next-line consistent-return
                return row.values[index] ?? ''
              })

            let obj
            if (colDefineIsArr) {
              arr.shift()
              obj = arr.reduce((a, v, i) => {
                if (colDefine?.[i]) return { ...a, [colDefine[i]]: v }
                return a
              }, {})
            } else {
              obj = arr.reduce((a, v, i) => {
                const key = Object.keys(colDefine)
                  .find(key => colDefine[key] === i)
                const value = v
                return {
                  ...a,
                  [key]: value,
                }
              })
            }

            resultArray.push(obj)
          })
        })
        resolve(resultArray)
      })
      .catch(error => {
        reject(error)
      })
  }
})
