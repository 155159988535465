var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": _vm.idProp,
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "md",
      "no-enforce-focus": "",
      "hide-footer": "",
      "header-bg-variant": "light-info",
      "title": _vm.title
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-backdrop",
      fn: function fn() {
        return [_c('div', {
          staticClass: "bg-dark w-100 h-100"
        })];
      },
      proxy: true
    }])
  }, [_vm.errorMessage ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm.errorMessage === 'Permission denied' ? _c('div', [_c('div', [_vm._v("Camera không khả dụng!!!")]), _c('div', [_vm._v("Vui lòng cấp quyền truy cập Camer của thiết bị. ")])]) : _c('div', [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])]) : _c('qrcode-stream', {
    attrs: {
      "constraints": _vm.cameraConstraints
    },
    on: {
      "decode": _vm.onDecode,
      "init": _vm.onInit,
      "error": _vm.onError
    }
  }), _vm.result ? _c('div', {
    staticClass: "my-1"
  }, [_c('div', {
    staticClass: "fw-600 text-info"
  }, [_vm._v(" Thông tin từ QR code: ")]), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.result))])]), _c('BButton', {
    staticClass: "w-100 mt-1",
    attrs: {
      "rounded": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.onApplyHandle
    }
  }, [_vm._v(" Đồng ý ")])], 1) : _c('div', {
    staticClass: "font-italic fw-600 d-flex-center"
  }, [_vm._v(" Vui lòng đưa QR code trên CCCD vào camera. ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }