import { extend, localize } from 'vee-validate'
import {
  required as ruleRequired,
  // email as ruleEmail,
  min as rule_min,
  min_value,
  max_value,
  max as rule_max,
  confirmed as ruleConfirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  // alpha_spaces as rule_alpha_spaces,
  length as rule_length,
  size as rule_size,
} from 'vee-validate/dist/rules'

import * as messages from '@/libs/i18n/locales'

import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorUsername,
  validatorCreditCard,
  validatorDate,
  validatorIsUnique,
  validatorUniqueTicketNumber,
  validatorValidTicket,
  validatorYesNo,
  validatorPhoneNumber,
  validatorIsBelongTo,
  validatorEmail,
  validatorDuplicatePhone,
  validatorAgencyWarningCredit,
  validatorAgencyLimitCredit,
  validatorMaximumCredit,
  validatorDuplicateParentId,
  validatorDuplicateImport,
  validateAgeGroup,
  validatorSpaces,
  validatorCode,
  validatorBookerId,
  validatorTtvVJ,
  validatorMinWords,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', ruleRequired)

export const email = extend('email', {
  validate: validatorEmail,
})

export const min = extend('min', rule_min)

export const minValue = extend('min_value', min_value)
export const maxValue = extend('maxValue', max_value)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', ruleConfirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const userName = extend('userName', {
  validate: validatorUsername,
})

export const code = extend('code', {
  validate: validatorCode,
})

export const password = extend('password', {
  validate: validatorPassword,
  message:
    'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const dateFormat = extend('dateFormat', {
  validate: validatorDate,
  message: 'Date field must be in the format "yyyy-MM-dd".',
})

export const isUnique = extend('isUnique', {
  validate: (val, params) => validatorIsUnique(val, params),
  params: ['module', 'type', 'oldValue'],
})

export const isUniqueTicketNumber = extend('isUniqueTicketNumber', {
  validate: (val, params) => validatorUniqueTicketNumber(val, params),
  params: ['items'],
})

export const validTicketNumber = extend('validTicketNumber', {
  validate: (val, params) => validatorValidTicket(val, params),
  params: ['invalidList'],
  message: '{_field_} không hợp lệ.',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
})

export const yesNo = extend('yesNo', {
  validate: validatorYesNo,
  message: '{_field_} is invalid.',
})

export const phoneNumber = extend('phoneNumber', {
  validate: validatorPhoneNumber,
})

export const duplicatePhone = extend('duplicatePhone', {
  validate: (val, params) => validatorDuplicatePhone(val, params),
  params: ['phone', 'arrayBackupPhone'],
  message: '{_field_} is duplicated.',
  // message: '{_field_} must be one of the following: {array}.',
})

export const agencyWarningCredit = extend('agencyWarningCredit', {
  validate: (val, params) => validatorAgencyWarningCredit(val, params),
  params: ['credit'],
  // message: '{_field_} must be one of the following: {array}.',
})

export const agencyLimitCredit = extend('agencyLimitCredit', {
  validate: (val, params) => validatorAgencyLimitCredit(val, params),
  params: ['credit'],
  // message: '{_field_} must be one of the following: {array}.',
})

export const agencyMaximumCredit = extend('agencyMaximumCredit', {
  validate: (val, params) => validatorMaximumCredit(val, params),
  params: ['credit'],
  // message: '{_field_} must be one of the following: {array}.',
})

export const alphaSpaces = extend('alphaSpaces', {
  validate: validatorSpaces,
  message:
    'The {_field_} field may only contain alphabetic characters as well as spaces',
})

export const duplicateParentId = extend('duplicateParentId', {
  validate: (val, params) => validatorDuplicateParentId(val, params),
  params: ['array'],
  message: '{_field_} đã bị trùng lặp.',
  // message: '{_field_} must be one of the following: {array}.',
})

export const duplicateImport = extend('duplicateImport', {
  validate: (val, params) => validatorDuplicateImport(val, params),
  params: ['array'],
  message: '{_field_} is duplicated.',
  // message: '{_field_} must be one of the following: {array}.',
})

export const isBelongTo = extend('isBelongTo', {
  validate: (val, params) => validatorIsBelongTo(val, params),
  params: ['array'],
  message: (fieldName, params) => `${fieldName} must be one of the following: ${params.array.join(', ')}.`,
  // message: '{_field_} must be one of the following: {array}.',
})

export const size = extend('size', rule_size)

export const ageGroup = extend('ageGroup', {
  validate: (birthday, flightSearch) => validateAgeGroup(birthday, flightSearch),
  params: ['typeFlight', 'departDate'],
  message: (birthday, flightSearch) => {
    let message = ''

    switch (flightSearch.departDate[1]) {
      case 'ADULT':
        message = 'Người lớn phải lớn hơn 12 tuổi!' // Adults must be over 12 years old
        break
      case 'CHILD':
        message = 'Trẻ em phải lớn hơn 2 tuổi và nhỏ hơn 12 tuổi!' // 'Children must be between 2 and 12 years old!'
        break
      case 'INFANT':
        message = 'Em bé phải nhỏ hơn 2 tuổi!' // 'Infant must be under 2 years old!'
        break
      default:
        break
    }
    return message
  },
})

export const minWord = extend('minWord', {
  validate: validatorMinWords,
  params: ['minWord', 'minCharacterPerWord'],
  message: (fieldName, { minWord, minCharacterPerWord }) => `${fieldName} phải gồm ít nhất ${minWord} từ, mỗi từ gồm ít nhất ${minCharacterPerWord} chữ cái.`,
})

export const bookerId = extend('bookerId', {
  validate: validatorBookerId,
})

export const ttvVJ = extend('ttvVJ', {
  validate: validatorTtvVJ,
})

const msgEn = messages.default.en.messagesList
const msgVi = messages.default.vi.messagesList

// Install English and Arabic localizations.
localize({
  en: {
    messages: messages.default.en.messagesList,
    names: {
      email: msgEn.Email,
      Password: msgEn.Password,
      confirmation: msgEn.confirmation,
      username: msgEn.username,
      isUnique: msgEn.isUnique,
      isUniqueTicketNumber: msgEn.isUnique,
      Phone: msgEn.phone,
      userName: msgEn.username,
      Status: msgEn.status,
      dob: msgEn.dob,
      'New Password': msgEn.newPassword,
      'Old Password': msgEn.oldPassword,
      'First Name': msgEn.firstName,
      'Last Name': msgEn.lastName,
      'User Name': msgEn.username,
      'Agency name': msgEn.agencyName,
      'Agency Owner': msgEn.agencyOwner,
      'Agency Manager': msgEn.agencyManager,
      'Send Monthly Report': msgEn.sendMonthReport,
      'Identity Card Number': msgEn.identityCardNumber,
      'Agency Warning Credit': msgEn.agencyWarningCredit,
      'Agency Limit Credit': msgEn.agencyLimitCredit,
      'ID Booker': msgEn.bookerId,
      ttvVJ: msgEn.ttvVJ,
    },
    fields: {
      username: {
        required: msgEn.required,
        min: msgEn.min,
        userName: msgEn.username,
      },
      email: {
        required: msgEn.required,
        email: msgEn.email,
        isUnique: msgEn.isUnique,
      },
      Password: {
        required: msgEn.required,
        min: msgEn.min,
        max: msgEn.max,
        confirmed: msgEn.confirmed,
      },
      'New Password': {
        required: msgEn.required,
        min: msgEn.min,
        confirmed: msgEn.confirmed,
      },
      confirmation: {
        required: msgEn.required,
        min: msgEn.min,
        confirmed: msgEn.confirmed,
      },
      Phone: {
        required: msgEn.required,
        phoneNumber: msgEn.phoneNumber,
      },
      'Agency Warning Credit': {
        agencyWarningCredit: msgEn.warningCredit,
      },
      'Agency Limit Credit': {
        agencyLimitCredit: msgEn.limitCredit,
        agencyMaximumCredit: msgEn.maximumCredit,
      },
      'ID Booker': {
        bookerId: msgEn.validateBookerId,
      },
      ttvVJ: {
        ttvVJ: msgEn.validateTtvVJ,
      },
      // 'Số vé': { // FIXME: làm i18n thì check
      //   isUniqueTicketNumber: '{_field_} is duplicated.',
      // },
    },
  },
  vi: {
    messages: messages.default.vi.messagesList,
    names: {
      email: msgVi.Email,
      Password: msgVi.Password,
      confirmation: msgVi.confirmation,
      Username: msgVi.username,
      isUnique: msgVi.isUnique,
      isUniqueTicketNumber: msgVi.isUnique,
      Phone: msgVi.phone,
      userName: msgVi.username,
      Status: msgVi.status,
      dob: msgVi.dob,
      'First Name': msgVi.firstName,
      'Last Name': msgVi.lastName,
      'Old Password': msgVi.oldPassword,
      'New Password': msgVi.newPassword,
      'User Name': msgVi.username,
      'Agency name': msgVi.agencyName,
      'Agency Owner': msgVi.agencyOwner,
      'Agency Manager': msgVi.agencyManager,
      'Send Monthly Report': msgVi.sendMonthReport,
      'Identity Card Number': msgVi.identityCardNumber,
      'Agency Warning Credit': msgVi.agencyWarningCredit,
      'Agency Limit Credit': msgVi.agencyLimitCredit,
      'ID Booker': msgVi.bookerId,
      ttvVJ: msgVi.ttvVJ,
    },
    fields: {
      email: {
        required: msgVi.required,
        email: msgVi.email,
        isUnique: msgVi.isUnique,
      },
      password: {
        required: msgVi.required,
        min: msgVi.min,
        max: msgVi.max,
        confirmed: msgVi.confirmed,
      },
      newPassword: {
        required: msgVi.required,
        min: msgVi.min,
      },
      confirmation: {
        required: msgVi.required,
        min: msgVi.min,
        confirmed: msgVi.confirmed,
      },
      Phone: {
        required: msgVi.required,
        phoneNumber: msgVi.phoneNumber,
      },
      'Agency Warning Credit': {
        agencyWarningCredit: msgVi.warningCredit,
      },
      'Agency Limit Credit': {
        agencyLimitCredit: msgVi.limitCredit,
        agencyMaximumCredit: msgVi.maximumCredit,
      },
      'ID Booker': {
        bookerId: msgVi.validateBookerId,
      },
      ttvVJ: {
        ttvVJ: msgVi.validateTtvVJ,
      },
      'Số vé': {
        isUniqueTicketNumber: '{_field_} đã bị trùng lặp.',
      },
    },
  },
})

// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
