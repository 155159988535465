<template>
  <BModal
    :id="idProp"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    no-enforce-focus
    hide-footer
    header-bg-variant="light-info"
    :title="title"
    @show="showHandle"
  >
    <!-- Tải ảnh lên -->
    <input
      type="file"
      @change="onFileChange"
    >

    <!-- Hiển thị ảnh và crop -->
    <div v-if="image">
      <div class="font-italic text-center">
        Vui lòng cắt ảnh vừa với QR code
      </div>

      <vue-cropper
        ref="cropperRef"
        :src="image"
        :aspect-ratio="ASPECT_RATIO"
        dragMode="crop"
        :view-mode="1"
        :auto-crop-area="1"
        style="width: 100%; max-height: 400px;"
      />

      <BButton
        rounded
        class="w-100 my-1"
        variant="warning"
        @click="getCroppedImage"
      >
        Quét QR
      </BButton>
    </div>

    <!-- Hiển thị ảnh đã crop -->
    <!-- <div v-if="croppedImage">
      <h3>Ảnh đã crop:</h3>
      <img
        :src="croppedImage"
        alt="Cropped Image"
      >
    </div> -->

    <div v-if="result">
      <div class="fw-600 text-info">
        Thông tin từ QR code:
      </div>
      <div>
        <h3>{{ result }}</h3>
      </div>
      <BButton
        rounded
        class="w-100 mt-1"
        variant="primary"
        @click="onApplyHandle"
      >
        Đồng ý
      </BButton>
    </div>
  </BModal>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { BarcodeDetector } from 'barcode-detector'
import { ref } from '@vue/composition-api'
import { BButton, BModal } from 'bootstrap-vue'

import useToast from '@useToast'

export default {
  components: {
    VueCropper, BModal, BButton,
  },
  props: {
    idProp: { type: String, default: 'modal-qr-code-scan-by-upload' },
    title: { type: String, default: 'Upload ảnh QR code CCCD' },
  },
  setup(props, { emit, root }) {
    const { toastError } = useToast()
    const image = ref(null)
    const croppedImage = ref(null)
    const ASPECT_RATIO = ref(1)
    const result = ref(null)

    const cropperRef = ref()

    function onFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = event => {
          image.value = event.target.result // Chuyển file thành URL
        }
        reader.readAsDataURL(file)
      }
    }

    function getCroppedImage() {
      const detector = new BarcodeDetector()
      // Get cropped canvas
      const croppedCanvas = cropperRef.value.getCroppedCanvas()
      croppedImage.value = croppedCanvas.toDataURL()
      // Convert the cropped canvas to an ImageBitmap
      croppedCanvas.toBlob(async blob => {
        if (!blob) {
          toastError('Lỗi cắt ảnh, vui lòng thử lại!')
          return
        }
        const imageBitmap = await createImageBitmap(blob)
        console.log({ imageBitmap })
        if (!imageBitmap) {
          toastError('Lỗi tải ảnh, vui lòng thử lại!')
          return
        }
        const detectedCodes = await detector.detect(imageBitmap)
        console.log({ detectedCodes })
        if (detectedCodes.length) {
          const splitArray = detectedCodes[0].rawValue.split('|')
          result.value = splitArray[0] || splitArray[1] || null
        } else {
          result.value = null
          toastError('Không có QR code trong ảnh, vui lòng thử lại!')
        }
      }, 'image/png') // Specify the desired image format
    }

    function showHandle() {
      image.value = null
      croppedImage.value = null
      result.value = null
    }

    function onApplyHandle() {
      emit('apply', result.value)
      root.$bvModal.hide(props.idProp)
    }

    return {
      image,
      croppedImage,
      ASPECT_RATIO,
      onFileChange,
      showHandle,
      getCroppedImage,
      cropperRef,
      result,
      onApplyHandle,
    }
  },
}
</script>

<style>

</style>
