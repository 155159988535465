export function trimInput(value) {
  return value.trim()
}

export function upperAndSpaceInput(value) {
  return value.toUpperCase().replace(/\s+/g, ' ')
}

export function trimAllInput(value) {
  if (!value) return ''
  return value.replace(/\s+/g, '')
}

export function trimAllInputAndUpper(value) {
  if (!value) return ''
  return value.toUpperCase().replace(/\s+/g, '')
}

export function trimUpperCaseInput(value) {
  return value.toUpperCase().trim()
}

export function lowerCaseFormatter(value) {
  return value.trim().toLowerCase()
}

export function upperCaseFormatter(value) {
  return value.trim().toUpperCase()
}

export function onlyUpperCaseFormatter(value) {
  return value.toUpperCase()
}

export function removeAccents(value) {
  if (!value) return ''
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function removeAccentsUpperCaseFormatter(value) {
  return removeAccents(value)
    .trim()
    .toUpperCase()
}

export function removeAccentsFormatter(value) {
  return removeAccents(value)
    .trim()
}

export function removeAccentsUpperCaseFormatterNotTrim(value) {
  return removeAccents(value).toUpperCase().replace(/\s+/g, ' ')
}

export function removeAccentsAndTrimAll(value) {
  const removeAccentVal = removeAccents(value)
  return trimAllInput(removeAccentVal)
}

export function formatterNameOnly(value) {
  return removeAccents(value)
    .replace(/\s+/g, ' ')
    .replace(/[^a-zA-Z\s]/g, '')
    .toUpperCase()
    .trim()
}

export function formatterPassportOnly(value) {
  return removeAccents(value)
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase()
}

export function formatPhoneNumber(value) {
  if (!value) return ''
  return value.replaceAll(/\+/g, '').replace(/ /g, '')
}

export default {
  trimInput,
  removeAccents,
}
