export const statusTourOptions = [
  { label: 'RESERVED', value: 'RESERVED', color: 'light-info' }, /**  Giữ chỗ (Hiển thị thêm thời gian giữ chỗ còn lại) */
  { label: 'BOOKED', value: 'BOOKED', color: 'warning' }, /**  Đặt chỗ */
  { label: 'REQUEST_PAYMENT', value: 'REQUEST_PAYMENT', color: 'light-warning' }, /**  Yêu cầu Đặt cọc */
  { label: 'REQUEST_PAYMENT_FULL', value: 'REQUEST_PAYMENT_FULL', color: 'warning' }, /**  Yêu cầu Thanh toán */
  { label: 'DEPOSITED', value: 'DEPOSITED', color: 'light-success' }, /**  Đã đặt cọc */
  { label: 'PAID', value: 'PAID', color: 'success' }, /**  Đã thanh toán */
  { label: 'CANCELLATION_REQUESTED', value: 'CANCELLATION_REQUESTED', color: 'light-danger' }, /**  Yêu cầu hủy */
  { label: 'CANCELLED', value: 'CANCELLED', color: 'danger' }, /**  Hủy */
]

export const STATUS_TOUR = statusTourOptions.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})

export const resolveColorByStatus = status => statusTourOptions.find(option => option.value === status)?.color || 'secondary'

export default {}
